import React from "react";
import IndexWeb from "../_components/IndexWeb";
import { BackgroundDesktop,MainBackground } from "../_components/Styles";
import FadeIn from "react-fade-in";
import Lottie2 from "../_components/Lottie2";
import Lottie3 from "../_components/Lottie3";
import FooterData from "./FooterData";

export default function Background() {
  return (
    <MainBackground className="animate__animated animate__fadeIn animate__slower">
      <BackgroundDesktop>
        <Lottie2 />
        <IndexWeb />
        <FadeIn className="containerFooter">
          <FooterData />
        </FadeIn>
        <Lottie3 />
      </BackgroundDesktop>
    </MainBackground>
  );
}
