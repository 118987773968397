import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../_animations/animation6.json";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const LottieWrapper = styled.div`
  margin: 0rem 0;
  position: relative;
  z-index: 0;
  margin-top: 12rem;
svg{
  background-color: ${({ theme }) => theme.background};
  border-radius:100%;
}
  @media screen and (max-width:1440px) {
    order:3;
    margin-top:0;
    
  }

`;

class LottieControl extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({}, (_) => this.setSpeed());
    this.setState({}, (_) => this.setSegments());
  }
  setSpeed() {
    this.setState({ speed: 5 });
  }
  setSegments() {
    this.setState({ segments: 0 });
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,

      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        <Lottie
          options={defaultOptions}
          height={isMobile ? 100 : 100}
          width={isMobile ? 100 : 100}
          speed={0.9}
          isClickToPauseDisabled={true}
        />
        {this.props.children}
      </>
    );
  }
}

export default function LottieAnimation6() {
  return (
    <LottieWrapper>
      <LottieControl />
    </LottieWrapper>
  );
}
