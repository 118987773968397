import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../_animations/animation5.json";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const LottieWrapper = styled.div`
  margin: 0rem 0;
  position: relative;
  margin-top: -2.8rem;
  height: 80px;
`;

class LottieControl extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({}, (_) => this.setSpeed());
    this.setState({}, (_) => this.setSegments());
  }
  setSpeed() {
    this.setState({ speed: 0 });
  }
  setSegments() {
    this.setState({ segments: 0 });
  }

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        <Lottie
          options={defaultOptions}
          height={isMobile ? 100 : 100}
          width={isMobile ? 350 : 500}
          speed={1.39}
          isClickToPauseDisabled={true}
        />
        {this.props.children}
      </>
    );
  }
}

export default function LottieAnimation5() {
  return (
    <LottieWrapper>
      <LottieControl />
    </LottieWrapper>
  );
}
