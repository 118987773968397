import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../_animations/animation2.json";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const LottieWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: -1;
  top: -1rem;
  right: ${isMobile ? "-1rem;" : "-1rem;"}
  transform: rotate(180deg);
  opacity: 70%;
  overflow-y: auto;
  
`;

class LottieControl extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({}, (_) => this.setSpeed());
    this.setState({}, (_) => this.setSegments());
  }
  setSpeed() {
    this.setState({ speed: 0 });
  }
  setSegments() {
    this.setState({ segments: 0 });
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,

      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        <Lottie
          options={defaultOptions}
          height={isMobile ? 900 : 800}
          width={isMobile ? 400 : 900}
          speed={0.9}
          isClickToPauseDisabled={true}
        />
        {this.props.children}
      </>
    );
  }
}

export default function LottieAnimation3() {
  return (
    <LottieWrapper>
      <LottieControl />
    </LottieWrapper>
  );
}
