import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import styled from "styled-components";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import Portfolio from "./Portfolio";
import ArticlePage from "../_pages/ArticlePage";

const HeaderComponent = styled.div`
  display: grid;
  column-gap: 1rem;
  position: relative;
  z-index: 1;
  @media (min-width: 1440px) {
    grid-template-columns: 1fr 2fr 1fr;
    max-width: 1600px;
    width: 100%;
  }

  .rightPanel {
    order: 3;

    @media screen and (max-width: 1440px) {
      order: 2;
    }
  }

  .detailJob {
    margin-bottom: 1rem;
    div {
      margin: 0;
    }
    order: 2;
    a {
      div {
        justify-content: center;
      }
    }
    @media screen and (max-width: 1440px) {
      order: 3;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem 1rem 1rem;
  z-index: 9999;
  position: relative;
  @media screen and (max-width: 1440px) {
    padding: 3rem 1rem 0rem 1rem;
  }
`;

export default function IndexWeb() {
  return (
    <HeaderContainer>
      <HeaderComponent>
        <LeftPanel />
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Portfolio} exact></Route>
            <Route path="/article/:name" component={ArticlePage} exact></Route>
          </Switch>
        </BrowserRouter>
        <RightPanel />
      </HeaderComponent>
    </HeaderContainer>
  );
}
