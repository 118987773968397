import React from "react";
import { BioData } from "./Messages";
import { ParagraphText } from "./Styles";

function Item({ Paragraph }) {
  return (
    <>
      <ParagraphText>{Paragraph}</ParagraphText>
    </>
  );
}

export default function BioDataContent({ show }) {
  return (
    <div>
      {(show && (
        <div className="animate__animated animate__fadeIn">
          {BioData.map((e, index) => (
            <Item Paragraph={e.Paragraph} key={index} />
          ))}
        </div>
      )) || (
        <>
          {BioData.slice(BioData.length - 1, BioData.length).map((e, index) => (
            <Item Paragraph={e.Paragraph} key={index} />
          ))}
        </>
      )}
    </div>
  );
}
