import React from "react";
import styled from "styled-components";

const IconWrapper = styled.div`
  color: white;
  font-size: 1.5rem;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  boder: none;
  background: ${({ theme }) => theme.icons};
  border-radius: 100%;
  transition: 400ms;
  &:hover {
    cursor: pointer;
    background: #72b5dc;
    transition: 500ms;
  }
  .fa-brands:before, .fa-file-lines:before{
    color: ${({ theme }) => theme.background};
  }
`;

const IconWrapper2 = styled(IconWrapper)`
  margin: 0;
  i{
    transition: 500ms;
    color: ${({ theme }) => theme.background};
  }
`;

const IconWrapperTheme = styled(IconWrapper)`
  font-size: 1rem;
  width: 1.2rem !important;
  height: 1.5rem !important;
  background: none;
  margin-top: 0;
  &:hover {
    background: none;
  }
`;

export default function Icons({ name }) {
  switch (name) {
    case "insta":
      return (
        <IconWrapper>
          <i className="fa-brands fa-instagram-square"></i>
        </IconWrapper>
      );
    case "github":
      return (
        <IconWrapper>
          <i className="fa-brands fa-github"></i>
        </IconWrapper>
      );
    case "face":
      return (
        <IconWrapper>
          <i className="fa-brands fa-facebook-square"></i>
        </IconWrapper>
      );

    case "linke":
      return (
        <IconWrapper>
          <i className="fa-brands fa-linkedin"></i>
        </IconWrapper>
      );
    case "cv":
      return (
        <IconWrapper>
          <i className="fa-solid fa-file-lines"></i>
        </IconWrapper>
      );
    case "wapp":
      return (
        <IconWrapper>
          <i className="fa-brands fa-whatsapp-square"></i>
        </IconWrapper>
      );
    case "plus":
      return (
        <IconWrapper aria-label="Read More" title="Read More">
          <i className="fa-solid fa-plus"></i>
        </IconWrapper>
      );
    case "less":
      return (
        <IconWrapper aria-label="Read Less" title="Read Less">
          <i className="fa-solid fa-minus"></i>
        </IconWrapper>
      );
    case "next":
      return (
        <IconWrapper2>
          <i className="fa-solid fa-arrow-right-long"></i>
        </IconWrapper2>
      );
    case "prev":
      return (
        <IconWrapper2>
          <i className="fa-solid fa-arrow-left-long"></i>
        </IconWrapper2>
      );
    case "night":
      return (
        <IconWrapperTheme>
          <i className="fa-solid fa-moon"></i>
        </IconWrapperTheme>
      );
    case "light":
      return (
        <IconWrapperTheme>
          <i className="fa-solid fa-sun"></i>
        </IconWrapperTheme>
      );

    case "navigate":
      return (
        <IconWrapper2>
          <i className="fa-solid fa-angle-right"></i>
        </IconWrapper2>
      );
      case "navigateless":
        return (
          <IconWrapper2>
            <i className="fa-solid fa-angle-left"></i>
          </IconWrapper2>
        );

    default:
      return (
        <IconWrapper>
          <i className="fa-solid fa-triangle-exclamation"></i>
        </IconWrapper>
      );
  }
}
