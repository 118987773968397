import React, { useState } from "react";

import { Titles, BoxContainer, Content, IconWrapper } from "./Styles";
import Icons from "./Icons";

export default function Box({ title, content, children, show }) {
  const [collapsed, setCollapsed] = useState(false);
  const Component = content;
  
  
  return (
    <BoxContainer className="animate__animated animate__backInLeft">
      <Titles>{title}</Titles>
      {(children && children) || (
        <>
          <Content >
            <Component show={collapsed}></Component>
            
          </Content>

          <IconWrapper onClick={() => setCollapsed (!collapsed )}>
            {(collapsed  && <Icons name="less" />) || <Icons name="plus" />}
          </IconWrapper>
        </>
      )}
    </BoxContainer>
  );
}
