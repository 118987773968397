import React, { useState, useEffect } from 'react';
import styled from "styled-components";


const CookieContainer = styled.div`
background-color: ${({ theme }) => theme.background};
color: ${({ theme }) => theme.mainFontColor};
box-shadow:${({ theme }) => theme.shadow};
position: fixed;
bottom: 0;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding:10px 10px 20px 10px;
text-align:center;
left:0;

`;

const ButtonWrap = styled.div`
width:100%;
display: flex;
flex-direction:row;
justify-content: center;
align-items: center;
`;


const Button = styled.button`
    width: auto;
  justify-self: center;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.iconShadows},  0 9px 12px 0 #0000001f;
  color:white;
  position: relative;
  overflow: hidden;
  transition: 500ms;
  border:none;
  margin: 0 10px;
  width:120px;
  text-transform:uppercase;
  font:weight:900;
  padding:4px 20px;
  background: ${({ theme }) => theme.icons};

  &:hover {
    cursor: pointer;
    background: #72b5dc;

     
`;

function CookiesBanner() {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7)
  let defaultTheme = "dark"

  const [showBanner, setShowBanner] = useState(true);



  useEffect(() => {
    const cookiesAccepted = sessionStorage.getItem('cookiesAccepted');
    const prevCookie = document.cookie.split(';').some((item) => item.trim().startsWith('themeSelection='));

    if (cookiesAccepted !== null || !prevCookie === false) {
      setShowBanner(false);
    }

  }, []);

  const acceptCookies = () => {
    setShowBanner(false);
    document.cookie = `themeSelection=${defaultTheme}; expires=${expirationDate}; path=/`;
  };

  const declineCookies = () => {
    setShowBanner(false);
    document.cookie = `themeSelection=false; expires=${expirationDate}; path=/`;
  };

  if (!showBanner) {
    return null;
  }

  return (
    <CookieContainer>
      <p>This site uses cookies to personalize your visual experience, adapting to your preferred settings each time you return to this portfolio.</p>
      <ButtonWrap>
        <Button onClick={acceptCookies}>Accept</Button>
        <Button onClick={declineCookies}>Decline</Button>
      </ButtonWrap>
    </CookieContainer>
  );
}

export default CookiesBanner;
