// import dragrace from "../_images/dragrace.jpg";
import walo from "../_images/walo.jpg";
import melias from "../_images/melias.jpg";
import mkt from "../_images/mkt.jpg";

import ezobord from "../_images/ezobord.jpg";
import ezobordRepSpace from "../_images/ezobordRepSpace.jpg";
import tektusDigital from "../_images/tektusDigital.jpg";
import timifurni from "../_images/timifurni.jpg";

import saffireClub from "../_images/saffire-club.jpg";
import saffireMcounty from "../_images/saffire-mcounty.jpg";
import saffireRiverside from "../_images/saffire-riverside.jpg";
import saffireCanadagames from "../_images/saffire-canadagames.jpg";
import saffireWestRodeo from "../_images/saffire-westrodeo.jpg";
import saffireClaycounty from "../_images/saffire-claycounty.jpg";
import saffireUvalde from "../_images/saffireUvalde.jpg";
import saffireFHill from "../_images/saffireFHill.jpg";
import saffireUpmc from "../_images/saffireUpmc.jpg";
import saffireVisitPalestine from "../_images/saffireVisitPalestine.jpg";
import saffireMower from "../_images/saffireMower.jpg";
import saffireClayCoMo from "../_images/saffireClayCoMo.jpg";
import saffireNashville from "../_images/saffireNashville.jpg";
import saffirePanhandle from "../_images/saffirePanhandle.jpg";
import saffireMenard from "../_images/saffireMenard.jpg";
import hoopsaffire from "../_images/hoopsaffire.jpg";
import tulsairishsaffire from "../_images/tulsairishsaffire.jpg";

import saffirePasadena from "../_images/saffirePasadena.jpg";
import williamsomSaffire from "../_images/williamsomSaffire.jpg";
import metraparkSaffire from "../_images/metraparkSaffire.jpg";
import bentonCountySaffire from "../_images/bentonCountySaffire.jpg";

import saffireSaffire from "../_images/saffireSaffire.jpg";
import saffireSummit from "../_images/saffireSummit.jpg";

import saffireSandiego from "../_images/saffireSandiego.jpg";
import saffirehalloffame from "../_images/saffirehalloffame.jpg";

import saffireflorida from "../_images/saffire-southflorida.jpg";

import saffireYester from "../_images/saffire-yesteryear.jpg";
import saffireAgZone from "../_images/saffire-agzone.jpg";

const showJob = true;

// PROFILE
export const PageData = {
  ProfileName: "Giorgio Ruanova",
  Profession: "SSR Frontend Developer",
  BioTitle: "Biography",
  Exptitle: "Experience ",
  Educationtitle: "Education",
  Skilltitle: "Skills ",
  Jobs: "Portfolio ",
};

// PORTFOLIO
export const PorfolioSummary = [
  {
    showItem: showJob,
    name: "jobDetail-100",
    Empresa: "Saffire LLC -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS - AOS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireSaffire,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },
  {
    showItem: showJob,
    name: "jobDetail-200",
    Empresa: "Saffire Summit -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS - AOS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireSummit,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-300",
    Empresa: "San Diego County Fair -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireSandiego,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },
  {
    showItem: showJob,
    name: "jobDetail-400",
    Empresa: "California Hall of Fame",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffirehalloffame,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },
    // ============================================
    {
      showItem: showJob,
      name: "jobDetail-400",
      Empresa: "South Florida Fair",
      Cliente: "Saffire LLC.",
      Country: "United States",
      Skills: "Frontend / UI Development",
      Technology: "Javascript - JQuery - SASS",
      Software: "Visual Studio Code - Adobe Suit",
      Type: "Mobile & Web App",
      Picture: saffireflorida,
      Detail:
        "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
    },
    {
      showItem: showJob,
      name: "jobDetail-031",
      Empresa: "Kubota Ag Zone",
      Cliente: "Saffire LLC.",
      Country: "United States",
      Skills: "Frontend / UI Development",
      Technology: "Javascript - JQuery - SASS",
      Software: "Visual Studio Code - Adobe Suit",
      Type: "Mobile & Web App",
      Picture: saffireAgZone,
      Detail:
        "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
    },

    {
      showItem: showJob,
      name: "jobDetail-030",
      Empresa: "Yester Year Village",
      Cliente: "Saffire LLC.",
      Country: "United States",
      Skills: "Frontend / UI Development",
      Technology: "Javascript - JQuery - SASS",
      Software: "Visual Studio Code - Adobe Suit",
      Type: "Mobile & Web App",
      Picture: saffireYester,
      Detail:
        "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
    },

  {
    showItem: showJob,
    name: "jobDetail-029",
    Empresa: "Pasadena Livestock Show  -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS - AOS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffirePasadena,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },
  
  {
    showItem: showJob,
    name: "jobDetail-028",
    Empresa: "Tulsa Irish Fest",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: tulsairishsaffire,
    Detail:
      "Led UI/UX new client design leveraging in-house global platform components, with a strong focus on enhancing performance. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },
  {
    showItem: showJob,
    name: "jobDetail-027",
    Empresa: "Williamson County Fair -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: williamsomSaffire,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-026",
    Empresa: "Metrapark -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: metraparkSaffire,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-025",
    Empresa: "Benton County Fair",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: bentonCountySaffire,
    Detail:
      "Led UI/UX redesign on existing client, with needs to have a sub site for specific event, with efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-024",
    Empresa: "Hooplandia",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: hoopsaffire,
    Detail:
      "Led UI/UX redesign on existing client, with needs to have a sub site for specific event, with efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },
  {
    showItem: showJob,
    name: "jobDetail-022",
    Empresa: "Menard County Fair - Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireMenard,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-021",
    Empresa: "Panhandle South Plains Fair",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffirePanhandle,
    Detail:
      "Led UI/UX new client design leveraging in-house global platform components, with a strong focus on enhancing performance. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },
  {
    showItem: showJob,
    name: "jobDetail-020",
    Empresa: "The Nashville Fair -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireNashville,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },
  {
    showItem: showJob,
    name: "jobDetail-019",
    Empresa: "Clay County Missoury -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireClayCoMo,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-018",
    Empresa: "Mower County Fair",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireMower,
    Detail:
      "Led UI/UX new client design leveraging in-house global platform components, with a strong focus on enhancing performance. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-017",
    Empresa: "Visit Palestine -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireVisitPalestine,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-016",
    Empresa: "UPMC Events Center -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireUpmc,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-015",
    Empresa: "Experience Fountain Hills -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS  - AOS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireFHill,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-014",
    Empresa: "Uvalde County Fairplex -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireUvalde,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-013",
    Empresa: "Clay County Agricultural Fair",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS  - AOS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireClaycounty,
    Detail:
      "Led UI/UX new client design leveraging in-house global platform components, with a strong focus on enhancing performance. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-012",
    Empresa: "West Texas Fair & Rodeo",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireWestRodeo,
    Detail:
      "Led UI/UX new client design leveraging in-house global platform components, with a strong focus on enhancing performance. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-011",
    Empresa: "Canada Games Park",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireCanadagames,
    Detail:
      "Led UI/UX new client design leveraging in-house global platform components, with a strong focus on enhancing performance. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-010",
    Empresa: "Martin County Chamber of Commerce",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireMcounty,
    Detail:
      "Led UI/UX new client design leveraging in-house global platform components, with a strong focus on enhancing performance. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    showItem: showJob,
    name: "jobDetail-009",
    Empresa: "Riverside County Fair Festival",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireRiverside,
    Detail:
      "Led UI/UX new client design leveraging in-house global platform components, with a strong focus on enhancing performance. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    show: showJob,
    name: "jobDetail-008",
    Empresa: "Club 19 -Redesign-",
    Cliente: "Saffire LLC.",
    Country: "United States",
    Skills: "Frontend / UI Development",
    Technology: "Javascript - JQuery - SASS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: saffireClub,
    Detail:
      "Led UI/UX redesign efforts by leveraging in-house global platform components, prioritizing performance enhancements. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },

  {
    show: showJob,
    name: "jobDetail-007",
    Empresa: "Timi Furniture",
    Cliente: "Tektus Digital",
    Country: "Canada",
    Skills: "UI/UX Developer",
    Technology: "Wordpress - Javascript - JQuery - CSS",
    Software: "Wordpress - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: timifurni,
    Detail:
      "Led UI/UX new client design leveraging in-house global platform components, with a strong focus on enhancing performance. Emphasis was placed on refining styling and seamlessly integrating brand elements from prototype designs.",
  },
  {
    show: true,
    name: "jobDetail-006",
    Empresa: "Tektus Digital -Redesign-",
    Cliente: "Tektus Digital",
    Country: "Canada",
    Skills: "UI/UX Developer",
    Technology: "Wordpress - Javascript - JQuery - CSS",
    Software: "Wordpress - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: tektusDigital,
    Detail:
      "Redesigned within an existing WordPress project, I crafted a user-friendly interface and user stories. From layout to features, I redesigned and developed additional components from scratch to deliver brand benefits, prioritizing digital solutions and service rendering. ",
  },

  {
    show: true,
    name: "jobDetail-005",
    Empresa: "EzoBord Internal Space -Redesign-",
    Cliente: "Tektus Digital",
    Country: "Canada",
    Skills: "UI/UX Developer",
    Technology: "Wordpress - Javascript - JQuery - CSS",
    Software: "Wordpress - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: ezobordRepSpace,
    Detail:
      "Led the redesign and customization of an existing WordPress portal project, with a focus on internal customers and providing essential tools through user mapping. Implemented a complete UI overhaul and updated layouts to streamline features for dealers during transactional negotiations.",
  },

  {
    show: true,
    name: "jobDetail-004",
    Empresa: "EzoBord -Redesign-",
    Cliente: "Tektus Digital",
    Country: "Canada",
    Skills: "UI/UX Developer",
    Technology: "Wordpress - Javascript - JQuery - CSS",
    Software: "Wordpress - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: ezobord,
    Detail:
      "I spearheaded a complete redesign within an existing WordPress project, crafting a user-friendly interface and user stories. From layout to features, I redesigned and developed additional components from scratch to exceed client expectations. This was followed by a marketing campaign aimed at increasing billing and financial activities.",
  },

  {
    show: true,
    name: "jobDetail-003",
    Empresa: "Lic. Maximiliano Torres",
    Cliente: "Jorge Ruanova",
    Country: "Argentina",
    Skills: "UX Design - UI Develop",
    Technology: "HTML - Bootstrap - SASS - AOS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: mkt,
    Detail:
      "Developed a website tailored for physical therapy professional, leveraging HTML, CSS, and JavaScript to deliver an intuitive UI/UX experience. Featuring a fresh layout and emphasizing the core aspects of physical therapy, it serves as a robust platform for practitioners in the field.",
    // site: "https://www.dramanuelitaelias.com",
  },

  {
    show: true,
    name: "jobDetail-002",
    Empresa: "Dra. Manuelita Elias",
    Cliente: "Jorge Ruanova",
    Country: "Argentina",
    Skills: "UX Design - UI Develop",
    Technology: "ReactJS - Styled Components - CSS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: melias,
    Detail:
      "Crafted a website catered to esthetic care professionals, harnessing ReactJS, HTML, CSS, and JavaScript to provide an engaging UI/UX journey. Boasting a refined layout and accentuating image care, beauty, and soft aesthetics, it stands as an indispensable platform for practitioners in the beauty industry.",
    // site: "https://www.dramanuelitaelias.com",
  },

  {
    show: true,
    name: "jobDetail-001",
    Empresa: "Walter Gandini",
    Cliente: "Jorge Ruanova",
    Country: "Argentina",
    Skills: "UX Design - UI Develop",
    Technology: "ReactJS - Styled Components - CSS",
    Software: "Visual Studio Code - Adobe Suit",
    Type: "Mobile & Web App",
    Picture: walo,
    Detail:
      "Introducing a visionary website build on ReactJS for eclectic artists, spanning music, painting, and tattoo artistry. With a boundless conception of art, it showcases a vivid array of colors and embraces a retro layout infused with modern and minimalist touches, epitomizing the essence of creativity without boundaries.",
    // site: "https://wg-newsite.netlify.app",
    // site2: "https://github.com/gioruanova/wg-newsite.git",
  },
];

// EXPERIENCIE
export const ExperienceData = [
  {
    // Empresa: "American Express S.A.",
    Año: "2007 - 2009",
    Cargo: "Customer Service Analyst.",
    // Detalle: "Customer service representative and cross selling analyst.",
  },

  {
    // Empresa: "American Express S.A.",
    Año: "2009 - 2011",
    Cargo: "Telephone Disputes Analyst",
    // Detalle:
    //   "Inbound call of domestic and foreign disputed transactions for Argentina market and recurring billing maintenance.",
  },

  {
    // Empresa: "American Express S.A.",
    Año: "2011 - 2014",
    Cargo: "Back-office Disputes Analyst",
    // Detalle:
    //   "Domestic and foreign disputes transactions for Argentina & Mexico markets.",
  },

  {
    // Empresa: "American Express S.A.",
    Año: "2014 - 2017",
    Cargo: "Real Time Workforce Specialist",
    // Detalle:
    //   "Develops and executes back-office inventory strategy for EMEA, JAPA & LACC hubs. Back Office volume extraction and distribution. Short/Long term strategies development to achieve service level agreement. Drive and deliver workforce planning, real time/service level adherence and inventory/call center performance management and analytics.",
  },
  {
    // Empresa: "American Express S.A.",
    Año: "2017 - 2018",
    Cargo: "BI Analyst",
    // Detalle:
    //   "Development and execute different KPI reports to support the operation strategy. Inventory trending analysis, automation rate, case time resolution and service       level agreement. Development and improvement of results measurements to support Pay for Performance programs for LACC region.",
  },
  {
    // Empresa: "American Express S.A.",
    Año: "2018 - 2019",
    Cargo: "AML Team Leader",
    // Detalle:
    //   "Development and creation of the center of excellence for LACC and EMEA Know your Customer process. Automation tools development and process lean. Direct reports: 20-30 hc.",
  },

  {
    // Empresa: "American Express S.A.",
    Año: "2019-2021",
    Cargo: "Commercial Account Executive",
    // Detalle:
    //   "Recurring billing facilities and payments implement. Business analysis and planning reporting. Automation and process lean.",
  },

  {
    Empresa: "American Express S.A.",
    Año: "2021 - 2022",
    Cargo: "AML Team Leader",
    // Detalle:
    //   "Owner of Back-Office and Outbound call process under AML & UIF Process for Argentina market, with direct local reports, with focus on automation and digital solutions. Direct reports: 15-20 hc.",
  },

  {
    Año: "2019 - 2022",
    Empresa: "Web Developer",
    Cargo: "Freelance",
  },

  {
    Empresa: "TEKTUS Digital (CAN)",
    Año: "2022 - 2022",
    Cargo: "UI /  UX DEVELOPER & PM",
    // Detalle:
    //   "Web site auditing consultant. Code review and refactoring. UX/UI development. QC standards and site maintenance.",
  },
  {
    Empresa: "Saffire LLC. (US)",
    Año: <b>2022 - to date</b>,
    Cargo: "FRONTEND DEVELOPER",
    // Detalle:
    //   "Web UI Developer using technologies: HTML, CSS/SASS, Javascript, JQuery, Bitbucket, Jira, Azure, Adobe Suit.",
  },
  // {
  //   Empresa: "Saffire (US)",
  //   Año: <b>2022 - to date</b>,
  //   Cargo: "FRONTEND DEVELOPER",
  //   // Detalle:
  //   //   "Web UI Developer using technologies: HTML, CSS/SASS, Javascript, JQuery, Bitbucket, Jira, Azure, Adobe Suit.",
  // },
];

// EDUCATION
export const EducationData = [
  {
    Curso: "Data Analytics",
    Institucion: "Digital House",
    // Detalle: "Data Analytics Immersion.",
    Año: "Feb - 2018",
  },
  {
    key: 1,
    Curso: "Leadership Program: LEAD",
    Institucion: "American Express S.A.",
    // Detalle: "Leadership workshop and negotiation skills.",
    Año: "May - 2019",
  },

  {
    key: 2,
    Curso: "HTML, CSS & JavaScript",
    Institucion: "Udemy",
    // Detalle: "Web design focused on Desktop an Mobile Apps.",
    Año: "Dec - 2020",
  },

  {
    key: 3,
    Curso: "React JS",
    Institucion: "Udemy",
    // Detalle: "Web design based on React JS library and dependencies.",
    Año: "Oct - 2021",
  },

  {
    key: 4,
    Curso: "Agile Certified",
    Institucion: "PMI - LIn Learning",
    // Detalle: "Agile principles and the agile mindset.",
    Año: "Apr - 2022",
  },

  {
    key: 5,
    Curso: "Frontend - REACT JS",
    Institucion: "Coderhouse",
    // Detalle: "Web Development - Javascript - React JS",
    Año: "2022 - 2023",
  },
  {
    key: 6,
    Curso: "Computer Science",
    Institucion: "Escuela Da Vinci",
    // Detalle: "Web Development - Javascript - React JS",
    Año: <b>2023 - to date</b>,
  },
];

// BIO
export const BioData = [
  {
    Paragraph:
      "I was born in Buenos Aires, Argentina, and have spent the majority of my career at American Express. Over the course of 15 years, I have had a strong focus on the Resource Planning Center, specializing in forecasting, business engineering, project analysis, and team leadership. This experience has allowed me to develop a deep understanding of optimizing processes and driving efficiency within the organization.",
  },

  {
    Paragraph:
      "Five years ago, I made a conscious decision to challenge myself and reignite an old passion from my past. That's when I dove back into the world of frontend development. With a renewed sense of purpose, I dedicated myself to honing my skills in usability and pixel-perfect design. Drawing from my previous experience, I seamlessly transitioned into this role and quickly found myself immersed in the art of coding and creating visually stunning websites and applications.",
  },

  {
    Paragraph:
      'Fuelled by my passion for technology, I am constantly seeking opportunities to expand my knowledge and stay up-to-date with the latest industry trends. I am not only dedicated to my formal studies in system analysis but also engage in self-directed learning, always exploring new technologies and methodologies. This drive for growth, coupled with my natural inclination for dynamic environments, fuels my desire to continually challenge myself and deliver a "wow" effect to clients.',
  },
  {
    Paragraph:
      "The coding mindset has become an integral part of my life. From daily tasks to life's challenges, I'm a strong believer that if there are variables involved, there is always room for improvement and the potential to find solutions. You only need to research, implement, test, and repeat it again, until you achieve your goal.",
  },
];

// *********SKILLS************************

export const SkillsData = [
  {
    type: "Javascript",
    level: 85,
  },

  {
    type: "JQuery",
    level: 80,
  },

  {
    type: "React JS",
    level: 70,
  },
  {
    type: "PHP",
    level: 55,
  },


  {
    type: "HTML5",
    level: 100,
  },

  {
    type: "CSS / SASS",
    level: 100,
  },

  {
    type: (
      <b>
        <u>Other Stacks / Tools</u> :
      </b>
    ),
    data: "Forebase / Firestore - JAVA & Android - Express JS -  Laravel - Adobe Suit - MYSQL / SQL Server - Bootstrap / Tailwind - Wordpress / Divi - GIT / Bitbucket / JIRA",
  },

  {
    type: (
      <b>
        <u>Other Skills</u> :
      </b>
    ),
    data: "OOP / SOLID / KISS / DRY - Clean Code - Frontend Performance - UI / UX Design / Atomic - Estimations - Agile / Scrum - SLA - CI / CD - Feedback Wrap - Leadership - Communication",
  },
];
