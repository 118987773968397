import React from "react";
import styled from "styled-components";
import { PageData } from "./Messages";
import Box from "./Box";
import Experience from "./Experience";
import Education from "./Education";
import BioData from "./BioData";


const RightPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width:1440px) {
    max-width:800px;
    width:100%;
    margin-right:0;
    margin:0 auto;
    }
`;

export default function RightPanel() {
  return (
    <div className="rightPanel animate__animated animate__backInRight">
      <RightPanelContent>
        <Box title={PageData.BioTitle} content={BioData} button />

        <Box title={PageData.Exptitle} content={Experience} button />

        <Box title={PageData.Educationtitle} content={Education} button />
      </RightPanelContent>
    </div>
  );
}
