import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../_animations/animation.json";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

function lottieMobile() {
  return (isMobile && "z-index: 1; top: -4rem") || " z-index: -1; top: -4rem;";
}

const LottieWrapper = styled.div`
  position: absolute;
  ${lottieMobile}
`;

class LottieControl extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({}, (_) => this.setSpeed());
    this.setState({}, (_) => this.setSegments());
  }
  setSpeed() {
    this.setState({ speed: 0 });
  }
  setSegments() {
    this.setState({ segments: 0 });
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,

      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        <Lottie
          options={defaultOptions}
          height={390}
          width={390}
          speed={0.2}
          isClickToPauseDisabled={true}
        />
        {this.props.children}
      </>
    );
  }
}

export default function LottieAnimation() {
  return (
    <LottieWrapper>
      <LottieControl />
    </LottieWrapper>
  );
}
