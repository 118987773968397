import DarkPNG from "../_images/DarkPNG.png";
import LightPNG from "../_images/LightPNG.png";
import { createGlobalStyle } from "styled-components";

export const LightTheme = {
  id: "LightTheme",
  mainTitles: "#0f79b4",
  mainFontColor: "#2c2c2c",
  mainFontColorUser: "white",
  mainFont: "'Montserrat', sans-serif;",
  background: "#dddddd",
  borders: "#0f79b4",
  gradient:
    "linear-gradient(90deg,rgb(35 72 93 /100%) 0%,rgb(73 155 202) 100%);",
    varlevel: "#b3b3b3",
  box: "#d4d7e3",
  boxhover: "#b6c3cd",
  boxmobile:
    "background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.93) 61%, rgba(255,255,255,0.88) 100%);background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.93) 61%,rgba(255,255,255,0.88) 100%); /* Chrome10-25,Safari5.1-6 */ackground: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.93) 61%,rgba(255,255,255,0.88) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e0ffffff',GradientType=0 ); /* IE6-9 */",

  pictureorder: "center",
  shadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;",
  iconShadows:
    "2px 3px 11px 0px #0003, 2px 6px 9px 0px #00000024, 19px 8px 20px 8px #0000001f",
  line: "black",
  icons: "#499bca",
  bar: "background: rgb(2,43,99); background: -moz-linear-gradient(top,  rgba(2,43,99,1) 0%, rgba(172,204,229,1) 100%);background: -webkit-linear-gradient(top,  rgba(2,43,99,1) 0%,rgba(172,204,229,1) 100%); background: linear-gradient(to bottom,  rgba(2,43,99,1) 0%,rgba(172,204,229,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#022b63', endColorstr='#accce5',GradientType=0 );",
  mobileoptions: "#3e3b3b",
  avatar: LightPNG,
  hoverjob:"black",

};

export const DarkTheme = {
  id: "DarkTheme",
  mainTitles: "#0f79b4",
  mainFontColor: "#ffff",
  mainFontColorUser: "white",
  mainFont: "'Montserrat', sans-serif;",
  background: "#212121",
  borders: "#842534",
  gradient:
    "linear-gradient(90deg,rgb(15 60 180 / 73%) 0%,rgb(134 211 247) 100%);",
    varlevel: "#143344",
  box: "#22292f",
  boxhover: "#768088",
  boxmobile:
    "background: -moz-linear-gradient(top,  rgba(34,41,47,1) 0%, rgba(34,41,47,0.93) 61%, rgba(38,42,45,0.88) 100%); /* FF3.6-15 */background: -webkit-linear-gradient(top,  rgba(34,41,47,1) 0%,rgba(34,41,47,0.93) 61%,rgba(38,42,45,0.88) 100%); /* Chrome10-25,Safari5.1-6 */background: linear-gradient(to bottom,  rgba(34,41,47,1) 0%,rgba(34,41,47,0.93) 61%,rgba(38,42,45,0.88) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#22292f', endColorstr='#e0262a2d',GradientType=0 ); /* IE6-9 */",

  pictureorder: "right",
  shadow:
    "rgb(195 198 216 / 14%) 0px 1px 3px, rgb(165 175 210 / 93%) 0px 1px 2px;",
  iconShadows:
    "0 8px 20px 7px #0003, 0 7px 7px 0 #00000024, 0 9px 12px 0 #0000001f;",
  line: "white",
  icons: "#187bca",
  bar: "background: rgb(2,43,99); background: -moz-linear-gradient(top,  rgba(2,43,99,1) 0%, rgba(172,204,229,1) 100%);background: -webkit-linear-gradient(top,  rgba(2,43,99,1) 0%,rgba(172,204,229,1) 100%); background: linear-gradient(to bottom,  rgba(2,43,99,1) 0%,rgba(172,204,229,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#022b63', endColorstr='#accce5',GradientType=0 )",
  mobileoptions: "#f9f3f3",
  avatar: DarkPNG,
  hoverjob:"white",
};

export const ThemeButtons = [
  {
    id: LightTheme.id,
    icon: "☼",
  },
  {
    id: DarkTheme.id,
    icon: "☽",
  },
];

export const Themes = [LightTheme, DarkTheme];
export const GlobalStyles = createGlobalStyle`

`;
