import React from "react";
import { ExperienceData } from "../_components/Messages";
import {
  CourseFont,
  CourseFont3,
  BoxTitle,
  BoxContent,
} from "./Styles";

function Item({ Empresa, Año, Cargo, Detalle }) {
  return (
    <>
      <BoxContent>
        <BoxTitle>
          <div className="experience-card">
            <CourseFont><b>{Empresa}</b></CourseFont>
            <p><i>{Cargo}</i></p>
          </div>
          <span>{Año}</span>
        </BoxTitle>
        {Detalle && <CourseFont3>{Detalle}</CourseFont3>}
      </BoxContent>
    </>
  );
}

export default function Experience({ show }) {
  return (
    <div>
      {(show && (
        <div className="animate__animated animate__fadeIn">
          {ExperienceData.slice(0)
            .reverse()
            .map((e, index) => (
              <Item
                key={index}
                Empresa={e.Empresa}
                Año={e.Año}
                Cargo={e.Cargo}
                Detalle={e.Detalle}
              />
            ))}
        </div>
      )) || (
        <>
          {ExperienceData.slice(
            ExperienceData.length - 2,
            ExperienceData.length
          )
            .reverse()
            .map((e, index) => (
              <Item
                key={index}
                Empresa={e.Empresa}
                Año={e.Año}
                Cargo={e.Cargo}
              />
            ))}
        </>
      )}
    </div>
  );
}
