import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../_animations/animation7.json";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const LottieWrapper = styled.div`
  position: relative;
  z-index: 0;
  margin-left: ${isMobile ? "-1rem" : "-3rem"};
  animation: animate-lottie 2s;

@keyframes animate-lottie {
  from {
  opacity: 0;
}

to {
  opacity:1;
}
`;

class LottieControl extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({}, (_) => this.setSpeed());
    this.setState({}, (_) => this.setSegments());
  }
  setSpeed() {
    this.setState({ speed: 5 });
  }
  setSegments() {
    this.setState({ segments: 0 });
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        <Lottie
          options={defaultOptions}
          height={isMobile ? 400 : 400}
          width={isMobile ? 280 : 350}
          speed={0.5}
          isClickToPauseDisabled={true}
        />
        {this.props.children}
      </>
    );
  }
}

export default function LottieAnimation7() {
  return (
    <LottieWrapper>
      <LottieControl />
    </LottieWrapper>
  );
}
