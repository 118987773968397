import React from "react";
import { Footer } from "../_components/Styles";

export default function FooterData() {
  return (
    <Footer>
      2018 - {new Date().getFullYear()}
      <div className="separator">|</div>
      <span className="name">Giorgio Ruanova. </span>
      <span className="copy"> All Rights Reserved.</span>
    </Footer>
  );
}
