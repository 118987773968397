import { ContainerPortfolioWeb } from "../_components/Styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PorfolioSummary } from "./Messages";
import {
  MainTitle,
  Subtitle,
  BoxContainerPortfolio,
  PictureJob1,
  HeaderJob,
  ContainerCards,
  TitlesPortfolio,
  BoxContainerPortfolioMobile,
  Detail
} from "./Styles";
import styled from "styled-components";
import Icons from "./Icons";
import Lottie6 from "../_components/Lottie6";
// import { isMobile } from "react-device-detect";

const ControlPage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  transition: 2s;
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  align-items: center;
  @media screen and (max-width: 1440px) {
    padding: 10px 0;
  }
`;

const ControlButton = styled.button`
  margin: 0 1rem 0 1rem;
  background: transparent;
  border: none;
  font-family: ${({ theme }) => theme.mainFont};
`;

export default function CentralPanel() {
  const [currentPage, setCurrentPage] = useState(0);
  const [animation, setAnimation] = useState("");

  const [show, setShow] = useState(false);
  setTimeout(() => {
    setShow(true);
  }, 400);

  function incrementPage() {
    setAnimation("animate__animated animate__fadeOut animate__fast");
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
    }, 600);
    setTimeout(() => {
      setAnimation("animate__animated animate__fadeIn animate__fast");
    }, 900);
  }

  function decrementPage() {
    setAnimation("animate__animated animate__fadeOut animate__fast");

    setTimeout(() => {
      setCurrentPage(currentPage - 1);
    }, 600);
    setTimeout(() => {
      setAnimation("animate__animated animate__fadeIn animate__fast");
    }, 900);
  }

  // =====================
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [pageQuantity, setpageQuantity] = useState(0);

  const mobileCards = 1;
  const desktopCards = 4;

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.width < 700) {
      setpageQuantity(mobileCards);
    } else {
      setpageQuantity(desktopCards);
    }
  }, [windowSize]);
  // =====================
  // const pageQuantity = 4;
  const totalPages = PorfolioSummary.length / pageQuantity;

  return (
    <>
      {show ? (
        <>
          <ContainerCards className="animate__animated animate__backInDown animate__fast">
            <BoxContainerPortfolioMobile>
              <TitlesPortfolio>MY WORK</TitlesPortfolio>
            </BoxContainerPortfolioMobile>

            <ContainerPortfolioWeb className={`${animation}`}>
              <>
                <>
                  {PorfolioSummary.slice(
                    currentPage * pageQuantity,
                    currentPage * pageQuantity + pageQuantity
                  ).map((e, index) => (
                    <Link
                      key={index}
                      to={`/article/${e.name}`}
                      style={{ textDecoration: "none" }}
                    >
                      <>
                        <BoxContainerPortfolio
                          title="Check Details"
                          aria-label="Check Details"
                        >
                          <HeaderJob>
                            <div>
                              <MainTitle>{e.Empresa}</MainTitle>
                              <Subtitle>{e.Cliente}</Subtitle>
                            </div>
                            <Icons name="navigate" />
                          </HeaderJob>
                          <>
                            <PictureJob1
                              src={e.Picture}
                              alt={`${e.Cliente} - ${e.Empresa}`}
                              title={`${e.Cliente} - ${e.Empresa}`}
                            ></PictureJob1>
                          </>
                          <Detail>{e.Skills}</Detail>
                        </BoxContainerPortfolio>
                      </>
                    </Link>
                  ))}
                </>
              </>
            </ContainerPortfolioWeb>
            <ControlPage>
              {currentPage > 0 && (
                <ControlButton
                  title="Previous Page"
                  aria-label="Previou Page"
                  onClick={decrementPage}
                >
                  <Icons name="prev" />
                </ControlButton>
              )}
              Page {currentPage + 1} of {Math.ceil(totalPages)}
              {PorfolioSummary.length > (currentPage + 1) * pageQuantity && (
                <ControlButton
                  title="Next Page"
                  aria-label="Next Page"
                  onClick={incrementPage}
                >
                  <Icons name="next" />
                </ControlButton>
              )}
            </ControlPage>
          </ContainerCards>
        </>
      ) : (
        <Lottie6 />
      )}
    </>
  );
}
