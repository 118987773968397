import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PageData } from "./Messages";
import Icons from "./Icons";
import { MainCard, MainCard2 } from "./Styles";
import Lottie from "../_components/Lottie";
import { isMobile } from "react-device-detect";
import Gl from "../_images/gl.png";

const MainContainer = styled.div`
width 100%;
margin-bottom: 2rem;
margin-top:${isMobile ? "2rem" : ""};

`;

const NameProfile = styled.div`
  text-align: center;
  border-radius: 0.5rem;
  position: relative;
`;

const Profession = styled.div`
  background: ${({ theme }) => theme.gradient};
  z-index:9999,
  width: auto;
  height: ${(isMobile && "80%;") || "100%;"} 
  justify-self: center;
  border-radius: 0.5rem;
  margin: ${isMobile ? "1rem;" : "1rem 0;"}
  box-shadow: ${({ theme }) => theme.iconShadows},  0 9px 12px 0 #0000001f;
  position: relative;
  overflow: hidden;
  transition: 500ms;
  
    
`;

const Social = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  z-index: 1999;
  position: relative;
`;

const Image = styled.div`
  background-image: url(${({ theme }) => theme.avatar});
  transition: 500ms;
  background-repeat: no-repeat;
  background-size: cover;
  width: 13.5rem;
  height: 13.5rem;
  justify-self: center;
  position: relative;
  z-index: 1;

  margin-top: 1rem;
  margin-bottom: 3rem;
  padding: 4.3rem 4.3rem 2.3rem 2.3rem;
`;

const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const BannerContent = styled.span`
  color: ${({ theme }) => theme.mainFontColor};
  background: ${({ theme }) => theme.box};
  border: 1px solid ${({ theme }) => theme.mainFontColor};
`;

export default function UserData() {
  const [counter, setCounter] = useState(1);
  const [animation, setAnimation] = useState("");
  const [banner, setBanner] = useState("");

  const handleClick = () => {
    setCounter(counter + 1);
  };

  useEffect(() => {
    if (counter >= 5) {
      setAnimation("show");
      setBanner("banner-show");
      setTimeout(() => {
        setAnimation("");
        setBanner("");
        setCounter(1);
      }, 3000);
    }
  }, [counter]);

  return (
    <>
      <MainContainer>
        <NameProfile>
          <div className="imgContainer animate__animated animate__zoomIn">
            <LottieContainer>
              <Lottie />
              <Image
                alt="Jorge Ruanova - SSR Frontend Developer"
                title="Jorge Ruanova - SSR Frontend Developer"
                onClick={() => handleClick()}
              />
              <img src={Gl} alt="" className={`gl-effect ${animation}`} />
              <BannerContent className={`banner ${banner}`}>
                Looking for something...?
              </BannerContent>
            </LottieContainer>
          </div>

          <MainCard aria-label={PageData.ProfileName}>
            {PageData.ProfileName}
          </MainCard>
          <div className="animate__animated animate__bounceIn">
            <Profession>
              <MainCard2>{PageData.Profession}</MainCard2>
            </Profession>
          </div>
          <Social className="animate__animated animate__fadeInUp">
            <a
              href="https://github.com/gioruanova"
              rel="noreferrer"
              target="_blank"
              title="GitHub Repo"
              aria-label="GitHub Repo"
            >
              <Icons name="github" />
            </a>
            <a
              href="https://www.instagram.com/gioruanova.dev/"
              rel="noreferrer"
              target="_blank"
              title="Dev Portfolio"
              aria-label="Dev Portfolio"
            >
              <Icons name="insta" />
            </a>

            <a
              href="https://www.linkedin.com/in/ruanovajorge/"
              rel="noreferrer"
              target="_blank"
              title="Linkedin"
              aria-label="Linkedin"
            >
              <Icons name="linke" />
            </a>

            <a
              href="_assets/CV.pdf"
              rel="noreferrer"
              target="_blank"
              title="My Resume"
              aria-label="My Resume"
            >
              <Icons name="cv" />
            </a>
          </Social>
        </NameProfile>
      </MainContainer>
    </>
  );
}
